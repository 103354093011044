import { WheelDataType } from "react-custom-roulette";
import { formatWheelData } from "../Utils/ArrayHelper";

export const Data: WheelDataType[] = formatWheelData([
	"KFC",
	"MCD",
	"Seoul Garden",
	"KyoChon",
	"Subway",
	"Mamak",
	"Texas Chicken",
	"Burger King",
	"Carl's Jr",
	"4 Fingers",
	"Manhattan Fish Market",
	"Taco Bell",
	"Murni Discovery",
	"Boat Noodle",
	"A&W",
	"Chicken Rice Shop",
	"Dubuyo",
	"Kenny Rogers",
	"Nando's",
	"Pizza Hut",
	"Secret Recipe",
	"Sushi",
	"Absolute Thai",
	"Big Apple Donuts",
	"Dunkin Donuts",
	"Fuel Shack",
	"Hokkaido Cheese Tart",
	"Hot & Roll",
	"IndoAsli",
	"K Fry",
	"Krispy Kreme",
	"Marry Brown",
	"Mr Tuk Tuk",
	"Mr Dakgalbi",
	"Myeongdong Topokki",
	"Rotiboy",
	"Sangkaya",
	"Jibril",
	"Sepiring",
	"Tony Roma's",
	"Dip n Dip",
	"Auntie Anne's",
	"Ayam Penyet",
	"Selera Kampung",
	"OldTown White Coffee",
	"Harum Manis",
	"Ah Cheng Laksa",
	"Din Tai Fung",
	"Jollibee",
	"Madam Kwan's",
	"Food Court",
	"Sala",
	"Lima Pulo",
	"Chamber 3",
	"28 Fireplace",
	"Soul Sacrifice",
	"Marmalade",
	"Merchant's Lane",
	"Chequers",
	"PODGY Kurau",
	"LOKL Coffee Co",
	"Bean Brothers KL",
	"Pokok KL",
	"F.R.COPPER",
]);
