import React, { useEffect, useState } from "react";
import "./App.css";

import { Wheel } from "react-custom-roulette";
import { Data } from "./Mock/Data";
import { shuffle } from "./Utils/ArrayHelper";
import Swal from "sweetalert2";
import { MdFastfood } from "react-icons/md";
import Footer from "./Components/Footer";

const App = () => {
	const [isSpinning, setSpinning] = useState<boolean>(false);
	const [prizeNumber, setPrizeNumber] = useState<number | null>(null);
	const [data, setData] = useState(Data);
	const [excluded, setExcluded] = useState<string[]>([]);

	useEffect(() => {
		setData(shuffle(data));
	}, []);

	const handleStopSpinning = () => {
		setSpinning(false);
		showResult();
	};

	const showResult = () => {
		const result = data[prizeNumber ?? 0].option;

		Swal.fire({
			title: `${result}!`,
			icon: "success",
			showCancelButton: true,
			confirmButtonText: "Ngam!",
			confirmButtonColor: "#3085d6",
			cancelButtonText: "Nope, spin again",
			footer: `<a href="${
				generateGoogleSearchURL(result, ["is", "wtf"], false) +
				"&tbm=isch"
			}" target="_blank">${result}? Tf is that.</a>`,
		}).then((response) => {
			if (
				response.isDismissed &&
				response.dismiss === Swal.DismissReason.cancel
			) {
				setTimeout(() => {
					handleStartSpinning(true);
				}, 150);

				return;
			}

			if (response.isConfirmed) {
				window.location.href = generateGoogleSearchURL(result, [
					"near",
					"me",
				]);
			}
		});
	};

	const generateGoogleSearchURL = (
		result: string,
		keywords: string[] = [],
		isAfter = true
	) => {
		const queryParams = result.toLowerCase().split(" ");

		keywords.forEach((keyword) => {
			return isAfter
				? queryParams.push(keyword)
				: queryParams.unshift(keyword);
		});

		return `https://www.google.com/search?q=${queryParams.join("+")}`;
	};

	const handleStartSpinning = (force = false) => {
		if (!force && isSpinning) return;

		if (prizeNumber !== null) {
			setExcluded([...excluded, data[prizeNumber].option]);
			setData(data.filter((_, i) => i !== prizeNumber));
		}

		const newPrizeNumber = Math.floor(Math.random() * data.length);

		setPrizeNumber(newPrizeNumber);
		setSpinning(true);
	};

	const reset = () => {
		window.scrollTo(0, 0);
		window.location.reload();
	};

	return (
		<>
			<div className='container'>
				<h1 className='title'>Nak makan mana?</h1>
				<div className='wheel-wrapper'>
					<Wheel
						mustStartSpinning={isSpinning}
						onStopSpinning={handleStopSpinning}
						prizeNumber={prizeNumber ?? 0}
						data={data}
						backgroundColors={[
							"rgb(37, 70, 25)",
							"rgb(30, 81, 40)",
							"rgb(49, 88, 24)",
						]}
						textColors={["rgb(222, 222, 222)"]}
						radiusLineWidth={1}
						outerBorderWidth={1}
						outerBorderColor={"#111"}
						innerBorderColor={"#111"}
						fontSize={12}
						textDistance={68}
					/>

					<div className='btn-spin-wrapper'>
						<button
							onClick={() => handleStartSpinning()}
							className={"btn " + (isSpinning ? "btn-spin" : "")}
							role='button'
						>
							{!isSpinning ? (
								"Spin"
							) : (
								<MdFastfood className='fast-food-icon' />
							)}
						</button>
					</div>

					{!!excluded.length && (
						<div
							onClick={reset}
							className='w-100 text-center text-white my-2 cursor-pointer'
						>
							Reset
						</div>
					)}
				</div>
				{!!excluded.length && (
					<div className='excluded-list'>
						<h4 style={{ marginBottom: 0 }}>Nopezzz:</h4>

						<ol>
							{excluded.map((nope: string, index: number) => {
								return (
									<li
										style={{ marginBottom: 8 }}
										key={nope + index}
									>
										<a
											style={{ color: "white" }}
											href={generateGoogleSearchURL(
												nope,
												["near", "me"]
											)}
											target='_blank'
										>
											{nope}
										</a>
									</li>
								);
							})}
						</ol>
					</div>
				)}
			</div>
			<Footer />
		</>
	);
};

export default App;
