const Footer = () => {
	return (
		<div
			style={{
				color: "rgb(222, 222, 222)",
				backgroundColor: "black",
				position: "fixed",
				bottom: 0,
				width: "100%",
				textAlign: "center",
				fontSize: 12,
				paddingTop: 10,
				paddingBottom: 10,
			}}
		>
			<span>
				Copyright © {new Date().getFullYear()}{" "}
				<a
					style={{
						color: "rgb(222, 222, 222)",
						fontWeight: 600,
					}}
					href='https://amienamry.dev'
					target='_blank'
				>
					Amien
				</a>
			</span>
			<span style={{ display: "block", marginTop: 5 }}>
				Background photo by{" "}
				<a
					style={{ color: "rgb(222, 222, 222)", fontWeight: 600 }}
					href='https://www.freepik.com/valeria-aksakova'
					target='_blank'
				>
					Valeria Aksakova
				</a>{" "}
				from{" "}
				<a
					style={{ color: "rgb(222, 222, 222)", fontWeight: 600 }}
					href='https://www.freepik.com'
					target='_blank'
				>
					Freepik
				</a>
			</span>
		</div>
	);
};

export default Footer;
