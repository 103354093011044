import { WheelDataType } from "react-custom-roulette";

export const shuffle = (array: any[]): any[] => {
	if (!array.length) {
		return [];
	}
	let currentIndex = array.length;
	let randomIndex = 0;

	// While there remain elements to shuffle.
	while (currentIndex != 0) {
		// Pick a remaining element.
		randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex--;

		// And swap it with the current element.
		[array[currentIndex], array[randomIndex]] = [
			array[randomIndex],
			array[currentIndex],
		];
	}

	return array;
};

export const formatWheelData = (array: string[]): WheelDataType[] => {
	return array.map((option) => {
		return { option };
	});
};
